/*! 2025-2-14 17:56:46 */
[data-v-043a3d16]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.record-drawer .container[data-v-043a3d16]{padding:0 20px}.record-drawer .container .top[data-v-043a3d16]{display:flex;flex-flow:row nowrap}.record-drawer .container .top>div[data-v-043a3d16]{margin-right:20px;width:220px;height:86px;border:1px solid #d8d8d8;border-radius:4px;padding-left:20px}.record-drawer .container .top>div h1[data-v-043a3d16]{font-size:28px;color:#333;margin:10px 0}.record-drawer .container .top>div p[data-v-043a3d16]{font-size:14px;color:#666666}.record-drawer .btn[data-v-043a3d16]{position:fixed;bottom:0;height:auto;width:1000px;margin-top:20px;padding:15px 20px;box-shadow:0px -2px 4px 0px rgba(223,223,223,0.5)}

[data-v-5eeaa1d2]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.log-wrapper[data-v-5eeaa1d2]{padding:5px 15px 15px 15px}.log-wrapper .list-item[data-v-5eeaa1d2]{margin-bottom:10px;line-height:26px;padding-left:5px}

[data-v-fdbea00e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.operationContent[data-v-fdbea00e]{width:100%;overflow:hidden}.operationContent span[data-v-fdbea00e]{width:calc(100% - 20px);display:inline-block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.text-ellipsis[data-v-fdbea00e]{-webkit-line-clamp:3;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis}[data-v-fdbea00e] .el-table__header,[data-v-fdbea00e] .el-table__body{border-left:1px solid #ebeef5}

[data-v-3d666a66]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.record-top[data-v-3d666a66]{display:flex;justify-content:space-between;background:#f5f7fa;border:1px solid #ebeef5;border-bottom:none;height:34px;line-height:34px;padding:0 20px}.record-top span[data-v-3d666a66]{margin-right:20px;font-family:'PingFang Bold'}.record-top span em[data-v-3d666a66]{font-weight:500;font-style:normal}.record-top span .link[data-v-3d666a66]{color:#409eff;-webkit-text-decoration-line:underline;text-decoration-line:underline;cursor:pointer}span.el-tag[data-v-3d666a66]{padding:0 !important;width:70px !important;text-align:center}.offer-container[data-v-3d666a66]{height:600px;overflow-y:auto}.wrapper-box[data-v-3d666a66]{text-align:center;position:relative}.wrapper-box .wrapper-image img[data-v-3d666a66]{width:64px;height:64px;margin-top:8px;border-radius:2px;-o-object-fit:cover;object-fit:cover;cursor:pointer}.wrapper-box .el-badge[data-v-3d666a66]{position:absolute;top:-5px;right:15px;z-index:9}.el-link[data-v-3d666a66]{display:contents}.product-image[data-v-3d666a66]{position:relative;padding:10px 0}.product-image img[data-v-3d666a66]{width:66px;height:66px;display:flex;margin:0 auto}.product-image span[data-v-3d666a66]{font-size:12px;position:absolute;top:5px;right:15px;background:#e8f4ff;border:1px solid #d1e9ff;color:#409eff;line-height:16px}


/*# sourceMappingURL=chunk-1c2a6634.842ccfe3.css.map*/