@import "~@/erp/styles/variables/variables.scss";











































































































































































































































































































































































































































.record-drawer {
  .container {
    padding: 0 20px;
    .top {
      display: flex;
      flex-flow: row nowrap;
      > div {
        margin-right: 20px;
        width: 220px;
        height: 86px;
        border: 1px solid rgba(216, 216, 216, 1);
        border-radius: 4px;
        padding-left: 20px;
        h1 {
          font-size: 28px;
          color: #333;
          margin: 10px 0;
        }
        p {
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
  .btn {
    position: fixed;
    bottom: 0;
    height: auto;
    width: 1000px;
    margin-top: 20px;
    padding: 15px 20px;
    box-shadow: 0px -2px 4px 0px rgba(223, 223, 223, 0.5);
  }
}
