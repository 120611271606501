@import "~@/erp/styles/variables/variables.scss";


















































































































































































































































































































































































































































































































































































































































































































































































































.record-top {
  display: flex;
  justify-content: space-between;
  background: #f5f7fa;
  border: 1px solid #ebeef5;
  border-bottom: none;
  height: 34px;
  line-height: 34px;
  padding: 0 20px;

  span {
    margin-right: 20px;
    font-family: 'PingFang Bold';

    em {
      font-weight: 500;
      font-style: normal;
    }

    .link {
      color: #409eff;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}

span.el-tag {
  padding: 0 !important;
  width: 70px !important;
  text-align: center;
}

.offer-container {
  height: 600px;
  overflow-y: auto;
}

.wrapper-box {
  text-align: center;
  position: relative;

  .wrapper-image {
    img {
      width: 64px;
      height: 64px;
      margin-top: 8px;
      border-radius: 2px;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .el-badge {
    position: absolute;
    top: -5px;
    right: 15px;
    z-index: 9;
  }
}

.el-link {
  display: contents;
}

.product-image {
  position: relative;
  padding: 10px 0;

  img {
    width: 66px;
    height: 66px;
    display: flex;
    margin: 0 auto;
  }

  span {
    font-size: 12px;
    position: absolute;
    top: 5px;
    right: 15px;
    background: #e8f4ff;
    border: 1px solid #d1e9ff;
    color: #409eff;
    line-height: 16px;
  }
}
